body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.audioCard {
  width: 20rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

.bioCard {
  width: 50rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

.podcastCard {
  width: 40rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

.quoteCard {
  width: 60rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contactCard {
  width: 20rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

.charityCard {
  width: 20rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tagline {
  margin-top: 10px;
}

.contactCard {
  margin-top: 15px;
}

@media screen and (max-width: 550px) {
  .resume {
    max-width: 450px;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 90% !important;
    height: 100% !important;
  }

  .under-tag-line {
    font-size: 20px;
  }

}

@media screen and (max-width: 380px) {
  .resume {
    max-width: 450px;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 90% !important;
    height: 100% !important;
  }

  .under-tag-line {
    font-size: 20px;
  }
}