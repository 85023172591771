.videoWrapper {
    position: relative;
    padding-bottom: 56.25% ; /* 16:9 */
    height: 0;
    margin-top: 15px;
  }

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% ;
    height: 100% ;
  }

.videoTitle {
    margin-top: 15px;
}

@media screen and (max-width: 550px) {
}

@media screen and (max-width: 380px) {
}