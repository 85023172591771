.logo {
  height: 150px;
}

.subtitle {
  font-style: italic;
  font-size: x-large;
  color: coral;
}

.featuredHouse {
  border-radius: 2px;
  background-color: rgb(255, 190, 166);
}

a.prev, a.next {
  text-decoration: none;
}